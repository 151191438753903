import React, { useEffect, useState } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { cn } from '../../lib/helpers';
import styles from './header.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    backgroundColor: 'var(--color-dark-black-bg) !important',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: 'all 0.5s ease',
  },
  appBarBackground: {
    transform: 'translate(0, -72px)',
  },
  left: {
    display: 'flex',
    flex: 1,
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      margin: '0 1rem',
      fontWeight: 400,
      cursor: 'pointer',
      color: 'var(--color-dark-black-bg)',
      height: '50px',
      textDecoration: 'none',
      borderBottom: '3px solid transparent',
    },
    '& span': {
      display: 'block',
    },
    '& > a:hover': {
      borderColor: 'var(--color-red-secondary-bg)',
      color: 'var(--color-red-secondary-bg)',
    },
  },
  active: {
    borderColor: 'var(--color-red-secondary-bg) !important',
    fontWeight: '700 !important',
  },
  activeSpan: {
    height: '70px !important',
  },
  right: {
    '& > button': {
      marginLeft: '0.25em',
    },
  },
  contactLink: {
    color: 'white',
  },
  drawer: {
    '& > div': {
      border: 'none',
    },
    backgroundColor: 'var(--color-dark-black-bg)',
  },
  drawerInner: {
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-dark-black-bg)',
    '& a': {
      color: 'var(--color-dark-black-bg)',
    },
  },
}));

const Header = ({
  fixedNav = false,
  smallHeader = false,
  siteTitle,
  siteSubtitle,
  heroImageCaption,
  backgroundImage,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [curPath, setCurPath] = useState();
  const classes = useStyles();

  useEffect(() => {
    setCurPath(window.location.pathname);
  });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
  });

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <StaticQuery
      query={graphql`
        query {
          mainMenu: sanityMenu(slug: { current: { eq: "main-menu" } }) {
            title
            links {
              _key
              title
              href
              hidden
            }
          }
          logo: file(relativePath: { eq: "icon-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          smLogo: file(relativePath: { eq: "favicons/apple-touch-icon-57x57.png" }) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={({ mainMenu: { links: linksArray }, logo, smLogo }) => {
        return (
          <div className={styles.root}>
            <div className="st-search-container" />
            <ClickAwayListener onClickAway={() => (drawerOpen ? setDrawerOpen(!drawerOpen) : null)}>
              <div>
                <AppBar className={cn(classes.appBar, trigger && classes.appBarBackground)}>
                  <Toolbar className={styles.toolbar} disableGutters>
                    <div className={styles.branding}>
                      <Img
                        className={styles.brandingImage}
                        loading="eager"
                        fluid={logo.childImageSharp.fluid}
                        alt="Academy of Art University"
                      />
                      <Link to="/">
                        <span className={styles.srOnly}>Go to home page</span>
                      </Link>
                    </div>
                    <div className={styles.contactContent}>
                      <p>
                        <a href="tel:+14152742222" target="_blank" className={classes.contactLink}>
                          Call 415-274-2222
                        </a>
                      </p>
                    </div>
                    <IconButton
                      className={styles.hamburger}
                      color="inherit"
                      aria-label="menu"
                      onClick={() => setDrawerOpen(!drawerOpen)}
                    >
                      <div className={cn(styles.navBurgerIcon, drawerOpen && styles.navBurgerIconOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </IconButton>
                  </Toolbar>
                  <Toolbar className={cn(styles.bottomBar, trigger && styles.expandBottomBar)} disableGutters>
                    <div className={classes.left}>
                      {trigger && (
                        <Img
                          className={styles.smallLogoImg}
                          loading="eager"
                          fluid={smLogo.childImageSharp.fluid}
                          alt="Academy of Art University"
                        />
                      )}
                      {linksArray.map(({ _key, title, href, hidden }) => {
                        if (hidden) {
                          return null;
                        }
                        const updatedHref = href?.slice(-1) === '/' ? href.slice(0, -1) : href;
                        const updatedCurPath = curPath?.slice(-1) === '/' ? curPath.slice(0, -1) : curPath;
                        return (
                          <a
                            href={href}
                            key={_key}
                            className={cn(
                              updatedHref === updatedCurPath && classes.active,
                              trigger && classes.activeSpan
                            )}
                          >
                            <span>{title}</span>
                          </a>
                        );
                      })}
                    </div>
                    <div className={classes.right}>
                      <a
                        href="https://www.academyart.edu/apply-for-admission/"
                        className={styles.applyBtn}
                        target="_blank"
                      >
                        <button className={styles.applyCustomBtn}>Apply</button>
                      </a>
                    </div>
                  </Toolbar>
                </AppBar>
                {/* leave this SHIM to push down content when fixedNav at top */}
                {fixedNav && <Toolbar />}
                <Drawer classes={{ root: classes.drawer }} variant={'persistent'} anchor="top" open={drawerOpen}>
                  <div className={classes.drawerInner}>
                    <nav className={styles.headerMenu}>
                      <div className={styles.headerMenuContent}>
                        {linksArray.map(({ _key, title, href, hidden }) => {
                          if (hidden) {
                            return null;
                          }

                          return (
                            <a href={href} key={_key}>
                              <div className={styles.headerMenuTitle}>{title}</div>
                            </a>
                          );
                        })}
                      </div>
                    </nav>
                  </div>
                </Drawer>
              </div>
            </ClickAwayListener>
            {backgroundImage && (
              <div className={cn(styles.mainImage, smallHeader && styles.smallMainImage)}>
                <Img
                  className={styles.hero}
                  loading="eager"
                  fluid={backgroundImage.childImageSharp.fluid}
                  alt="Academy of Art University"
                />
                {siteTitle && <h3 className={styles.heroTitle}>{siteTitle}</h3>}
                {siteSubtitle && <h1 className={styles.title}>{siteSubtitle}</h1>}
                {heroImageCaption && <figcaption className={styles.heroImageCaption}>{heroImageCaption}</figcaption>}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default Header;
