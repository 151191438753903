import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { generateSocialLinks } from '../../utils/tools';
import MenuLink from './menu-link';

import styles from './footer.module.css';

const Footer = () => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "icon-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          appStoreButton: file(relativePath: { eq: "icon-social-download-app.png" }) {
            childImageSharp {
              fixed(width: 72) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          facebookIcon: file(relativePath: { eq: "icon-social-facebook.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          instagramIcon: file(relativePath: { eq: "icon-social-instagram.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          twitterIcon: file(relativePath: { eq: "icon-social-twitter.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          tikTokIcon: file(relativePath: { eq: "icon-social-tiktok.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          youtubeIcon: file(relativePath: { eq: "icon-social-youtube.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          spotifyIcon: file(relativePath: { eq: "icon-social-spotify.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          courses: allSanityCourse {
            nodes {
              ...ClassPreview
            }
          }
          footer: sanityMenu(slug: { current: { eq: "footer-links" } }) {
            title
            links {
              _key
              title
              href
              hidden
            }
          }
        }
      `}
      render={({
        courses,
        logo,
        appStoreButton,
        facebookIcon,
        twitterIcon,
        youtubeIcon,
        instagramIcon,
        spotifyIcon,
        tikTokIcon,
        footer: { links: footerLinks },
      }) => {
        // split into 4 groups
        return (
          <div className={styles.root}>
            <div className={styles.container}>
              <div className={styles.wrapper}>
                <div className={styles.branding}>
                  <Link to="/">
                    <Img
                      className={styles.brandingImage}
                      fluid={logo.childImageSharp.fluid}
                      alt="Academy of Art University"
                    />
                  </Link>
                </div>
                <div className={styles.socialIcons}>
                  {generateSocialLinks(
                    [
                      'https://www.facebook.com/AcademyofArtUniversity',
                      'https://twitter.com/academy_of_art',
                      'http://www.youtube.com/user/academyofartu',
                      'https://www.instagram.com/academy_of_art',
                      'https://open.spotify.com/show/2z1VI3JJJy4SCUSuMi6D3o?si=2mnl3fmSRAisKEvmBdgQfQ',
                      'https://www.tiktok.com/@academyofartuniversity',
                    ],
                    {
                      facebook: facebookIcon,
                      twitter: twitterIcon,
                      youtube: youtubeIcon,
                      instagram: instagramIcon,
                      spotify: spotifyIcon,
                      tiktok: tikTokIcon,
                    }
                  )}
                </div>
              </div>
              <div className={styles.footerBottom}>
                <div className={styles.footerContact}>
                  <div className={styles.footerHeading}>Get in touch</div>
                  <div className={styles.contactInfo}>
                    <address>
                      <div>
                        <a type="tel" href="tel:1-800-544-2787">
                          1-800-544-2787
                        </a>
                      </div>
                      <div>79 New Montgomery St.</div>
                      <div>San Francisco, CA 94105</div>
                    </address>
                    <div>
                      <a
                        className={styles.footerCta}
                        target="_blank"
                        rel="noopener"
                        href="https://www.google.com/maps/dir//Academy+of+Art+94105"
                      >
                        Get Directions
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.footerSchools}>
                  <div className={styles.footerHeading}>School Topics</div>
                  <ul className={styles.footerColumns}>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/about-us/accreditation/`}>Accreditation</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/disclosures/#bppe`}>
                        Bureau for Private Postsecondary Education
                      </a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/disclosures/`}>Disclosures</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/policies/`}>Student Code of Conduct</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/job-opportunities/`}>Job Opportunities</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/campus-athletics/campus-safety/`}>Campus Safety</a>
                    </li>
                  </ul>
                </div>
                <div className={styles.footerSchools}>
                  <div className={styles.footerHeading}>Links & Resources</div>
                  <ul className={styles.footerColumns}>
                    <li className={styles.columnLink}>
                      <a
                        href={`https://mediacenter.academyart.edu/link/11527-CARES_Act_Disclosures_5.29.pdf/play?player=0`}
                      >
                        Cares Act Student/Institutional Funding
                      </a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/about-us/`}>About Us</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/press/`}>Press</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://mediacenter.academyart.edu/link/8480/play?player=0`}>Academy Life Brochure</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/campus-athletics/accessibility/`}>Accessibility Resources</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://catalog.academyart.edu/catalog/`}>Course Catalog</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/campus-athletics/spring-show/`}>Spring Show</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a
                        href={`https://cdn-academywww.pressidium.com/wp-content/uploads/academy-art-u-campus-map.pdf?pdf=campus-map`}
                      >
                        Campus Map
                      </a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/about-us/completing-your-courses-in-an-emergency/`}>
                        Emergency Response
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={styles.footerSchools}>
                  <div className={styles.footerHeading}>Info For</div>
                  <ul className={styles.footerColumns}>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/explore/prospective-student/`}>Prospective Student</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/explore/parents-families/`}>Parents & Families</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/admissions/transfer-students/`}>Transfer Students</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/explore/industry-leader/`}>Industry Leader</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/explore/international-student/`}>International Students</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://www.academyart.edu/explore/military-student/`}>Military Student</a>
                    </li>
                    <li className={styles.columnLink}>
                      <a href={`https://my.academyart.edu/login?use=login`}>{'STUDENT LOGIN >>>'}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.footerCopyright}>
                <div>© {new Date().getFullYear()} Academy of Art University&nbsp;</div>
                <ul className={styles.footerCopyrightLinks}>
                  {footerLinks.map(({ _key, title, href, hidden }) => (
                    <li key={_key}>
                      <MenuLink title={title} href={href} hidden={hidden} />
                    </li>
                  ))}
                </ul>
                <div className={styles.socialIcons}>
                  {generateSocialLinks(
                    [
                      'https://www.facebook.com/AcademyofArtUniversity',
                      'https://twitter.com/academy_of_art',
                      'http://www.youtube.com/user/academyofartu',
                      'https://www.instagram.com/academy_of_art',
                      'http://www.spotify.com/academyofartuni',
                      'http://tiktok.com/academyofartu',
                    ],
                    {
                      facebook: facebookIcon,
                      twitter: twitterIcon,
                      youtube: youtubeIcon,
                      instagram: instagramIcon,
                      spotify: spotifyIcon,
                      tiktok: tikTokIcon,
                    }
                  )}
                  <a target="_blank" href="http://apple.co/1I6ySIP">
                    <Img fixed={appStoreButton.childImageSharp.fixed} alt="Academy of Art on Apple App Store" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default Footer;
